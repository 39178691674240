//
// reboot.scss
//

body {
  padding-right: 0px !important;
  padding-left: 0px !important;
  &.loading {
    visibility: hidden;
  }
}

button, a {
  outline: none !important;
}

// Forms

label {
  font-weight: $font-weight-semibold;
}

// Address
address.address-lg {
  line-height: 24px;
}

b, strong {
  font-weight: $font-weight-bold;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none{
  user-select:none;
  -webkit-user-select:none;
}

.text-overflow-ellipses {
  border: 2px solid #2a70a5;
  // padding: 20px 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  // text-overflow: ellipsis;
  // font-size: 50px;
}