//
// react-table.scss
//

.react-table {

  th,
  td {
    white-space: nowrap;
  }

  th {
    padding-left : $table-cell-padding-y;
    padding-right: 30px;
  }

  .sortable {
    position: relative;
    cursor: pointer;
    user-select:none;
    -webkit-user-select:none;
  
    &:before {
      position   : absolute;
      right      : 10px;
      left       : auto;
      content    : "\F0360";
      font-family: "Material Design Icons";
      font-size  : 24px;
      top        : -5px;
      opacity    : 0.5;
    }

    &:after {
      position   : absolute;
      right      : 10px;
      left       : auto;
      content    : "\F035D";
      font-family: "Material Design Icons";
      font-size  : 24px;
      top        : 5px;
      opacity    : 0.5;
    }

    &.sorting_asc {
      &:before {
        opacity: 1;
        color: var(--ct-primary);
      }
    }

    &.sorting_desc {
      &:after {
        opacity: 1;
        color: var(--ct-primary);
      }
    }
  }
}